import "./styles/Styles.scss";
import { useState, useEffect } from "react";
import HomeScreen from "./screens/HomeScreen";
import MenuScreen from "./screens/MenuScreen";
import GameScreen from "./screens/GameScreen";
import LobbyScreen from "./screens/LobbyScreen";
import ScenarioEditor from "./screens/ScenarioEditor";
import AdminScreen from "./screens/AdminScreen";

import { resize } from "./functions";
import { events } from "./ServerCommunication";
import Events from "./Events";
import { Routes, Route, MemoryRouter } from "react-router-dom";
import Popup from "./components/Popup";
import ConfirmModal from "./components/ConfirmModal";

import { ApolloProvider, ApolloClient, InMemoryCache } from "@apollo/client";
import EndScreen from "./components/EndScreen";
import Connecting from "./screens/Connecting";

declare global {
  var socket: WebSocket | null;
  var globalEvents: Events;
  var username: string;
  var email: string;
  var password: string;
  var url: string;
  var online: boolean;
  var topZIndex: number;
  var mousePos: any;
  var selectedElement: any;
  var uploadServerUrl: string;
  var graphQlURL: string;
  var superAdmin: boolean;
  var dpr: number;
  var authToken: string;
  var isFacilitator: boolean;
  var playersData: any;
}

const LOCALHOST_CONFIG = {
  IMAGE_SERVER_URL: "http://localhost:7001/images/",
  WEBSOCKET_URL: "ws://localhost:8000",
  UPLOAD_SERVER_URL: "https://staging.wescrumble.com:7102/upload/",
  SIGNUP_URL: "http://localhost/register/",
  GRAPHQL_URL: "http://localhost:11000/graphql",
};

const STAGING_CONFIG = {
  IMAGE_SERVER_URL: "http://staging.wescrumble.com:7001/images/",
  WEBSOCKET_URL: "ws://staging.wescrumble.com:7000",
  UPLOAD_SERVER_URL: "https://staging.wescrumble.com:7102/upload/",
  SIGNUP_URL: "http://staging.wescrumble.com/register/",
  GRAPHQL_URL: "http://staging.wescrumble.com:7006/graphql",
};
const STAGING_CONFIG_HTTPS = {
  IMAGE_SERVER_URL: "https://staging.wescrumble.com:7101/images/",
  UPLOAD_SERVER_URL: "https://staging.wescrumble.com:7102/upload/",
  WEBSOCKET_URL: "wss://staging.wescrumble.com:7100",
  SIGNUP_URL: "'https://staging.wescrumble.com/register/',",
  GRAPHQL_URL: "https://staging.wescrumble.com:7106/graphql",
};
const LIVE_CONFIG_HTTPS = {
  IMAGE_SERVER_URL: "https://wescrumble.com:7104/images/",
  WEBSOCKET_URL: "wss://wescrumble.com:7103",
  UPLOAD_SERVER_URL: "https://wescrumble.com:7105/upload/",
  SIGNUP_URL: "https://www.wescrumble.com/register/",
  GRAPHQL_URL: "https://wescrumble.com:7107/graphql",
};
const MARK_CONFIG = {
  IMAGE_SERVER_URL: "http://192.168.0.120:10000/upload",
  WEBSOCKET_URL: "ws://192.168.0.120:8000",
  UPLOAD_SERVER_URL: "https://staging.wescrumble.com:7102/upload/",
  SIGNUP_URL: "http://192.168.0.120/register/",
  GRAPHQL_URL: "http://192.168.0.120:11000/graphql",
};

const MARK_CONFIG_REMOTE = {
  IMAGE_SERVER_URL: "http://localhost:10000/upload",
  WEBSOCKET_URL: "ws://localhost:8000",
  UPLOAD_SERVER_URL: "https://staging.wescrumble.com:7102/upload/",
  SIGNUP_URL: "http://localhost/register/",
  GRAPHQL_URL: "http://localhost:11000/graphql",
};

const config = STAGING_CONFIG_HTTPS; //MARK_CONFIG;

globalThis.uploadServerUrl = config.UPLOAD_SERVER_URL;
globalThis.graphQlURL = config.GRAPHQL_URL;
globalThis.dpr = window.devicePixelRatio;
globalThis.superAdmin = false;
globalThis.mousePos = { x: 0, y: 0 };
globalThis.topZIndex = 50;
//globalThis.url = "ws://staging.wescrumble.com:7000";
globalThis.url = config.WEBSOCKET_URL;
//globalThis.url = "ws://localhost:8016";
globalThis.globalEvents = new Events();
globalThis.isFacilitator = false;
globalThis.globalEvents.registerEvent("triggerPopup");
globalThis.globalEvents.registerEvent("reloadImages");

for (const event in events) {
  globalThis.globalEvents.registerEvent(event);
}
document.body.style.overscrollBehaviorY = "contain";
window.addEventListener("contextmenu", function (e) {
  e.preventDefault();
});

function App() {
  const [triggerPopup, setTriggerPopup] = useState<any>({});
  const [connecting, setConnecting] = useState<boolean>(false);
  const client = new ApolloClient({
    uri: globalThis.graphQlURL,
    cache: new InMemoryCache(),
  });

  useEffect(() => {
    globalThis.globalEvents.addEventListener("triggerPopup", showPopup);
    resize();
    window.onresize = resize;
    if (!globalThis.socket) {
      let socket = new WebSocket(globalThis.url);
      socket.onopen = socketOnOpen;
      socket.onerror = socketOnError;
      socket.onclose = socketOnClose;
      socket.onmessage = socketOnMessage;
      globalThis.socket = socket;
      //   globalThis.authToken = socket.id
    }
    document.addEventListener("wheel", wheelBlur);
    return function cleanup() {
      globalThis.globalEvents.removeEventListener("triggerPopup", showPopup);
      document.removeEventListener("wheel", wheelBlur);
    };
  }, []);

  function wheelBlur() {
    //@ts-ignore
    if (document?.activeElement?.type === "number") {
      //@ts-ignore
      document.activeElement?.blur();
    }
  }

  function showPopup(data: any) {
    let newData = JSON.parse(data);
    setTriggerPopup({ trigger: true, content: newData.content, color: newData.color, duration: newData.duration });
  }

  function socketOnClose() {
    globalThis.online = false;
    globalThis.socket = null;
    console.log("socket closed");
    globalThis.authToken = "";
    setConnecting(true);
    setTimeout(() => {
      reconnect();
    }, 3000);
  }

  function reconnect() {
    console.log("attempting to reconnect");
    if (!globalThis.online && !globalThis.socket) {
      let socket = new WebSocket(globalThis.url);
      socket.onopen = socketOnOpen;
      socket.onerror = socketOnError;
      socket.onclose = socketOnClose;
      socket.onmessage = socketOnMessage;
      globalThis.socket = socket;
    }
  }

  function socketOnError() {
    globalThis.socket?.close();
    globalThis.authToken = "";
    console.log("socket error");
  }

  function socketOnMessage(e: any) {
    let data = JSON.parse(e.data);
    for (const event in events) {
      if (data.event === event) {
        globalThis.globalEvents.triggerEvent(event, data);
      }
    }
  }

  function socketOnOpen() {
    globalThis.online = true;
    console.log("socket open");
    setConnecting(false);
  }

  return (
    <div className="App">
      <ApolloProvider client={client}>
        <div className="container">
          <div className="main-container">
            {connecting && <Connecting />}
            <Popup popup={triggerPopup} setTriggerPopup={setTriggerPopup}></Popup>
            <ConfirmModal />
            <EndScreen />
            {!connecting && (
              <MemoryRouter>
                <Routes>
                  <Route path="/" element={<HomeScreen />} />
                  <Route path="/menu" element={<MenuScreen />} />
                  <Route path="/game" element={<GameScreen />} />
                  <Route path="/lobby" element={<LobbyScreen />} />
                  <Route path="/editor" element={<ScenarioEditor />} />
                  <Route path="/admin-panel" element={<AdminScreen />} />
                </Routes>
              </MemoryRouter>
            )}
            {connecting && (
              <MemoryRouter>
                <Routes>
                  <Route path="/" element={<HomeScreen />} />
                </Routes>
              </MemoryRouter>
            )}
          </div>
        </div>
      </ApolloProvider>
    </div>
  );
}

export default App;
